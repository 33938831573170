import {twMerge} from 'tailwind-merge';
import roundNumber from 'src/utils/roundNumber';
import {ratingStar} from 'src/utils/icons';
import RemixIcon from './RemixIcon';

type Props = {
  rating: number | undefined;
  className?: string;
  starSize?: number;
  starColor?: string;
};

const SmallRating = ({rating, className, starSize = 11, starColor}: Props) => {
  if (!rating) return null;

  return (
    <div className={twMerge('flex items-center text-sm font-bold text-(--text-color)', className)}>
      {roundNumber(rating)}

      <div className="ml-[3px] mb-[2px]">
        <RemixIcon icon={ratingStar} size={starSize} color={starColor || 'var(--purple-text)'} />
      </div>
    </div>
  );
};

export default SmallRating;
